import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

// let instance

/** Returns the country code set in the browser preferences. */
function __getBrowserLocaleCountryCode () {
  const navigatorLocale =
    navigator.languages !== undefined
      ? navigator.languages[0]
      : navigator.language

  if (!navigatorLocale) {
    return undefined
  }

  return navigatorLocale.trim().split(/-|_/)[0]
}

/** Returns the locales supported by the application in the locales directory. */
function __getSupportedLocales () {
  return require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
}

function __getInitialLocale () {
  const browserLocale = __getBrowserLocaleCountryCode()
  if (Object.keys(__getSupportedLocales()).includes(browserLocale)) {
    return browserLocale
  }

  return process.env.VUE_APP_I18N_LOCALE || 'en'
}

function __getLocaleMessages () {
  const locales = __getSupportedLocales()
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

// function getInstance () {
//   console.log('TRACE-REMOVE: instance is: ' + instance)
//   if (typeof instance === 'undefined') {
//     console.log('TRACE-REMOVE: create i18n!')
//     instance = new VueI18n({
//       // locale: process.env.VUE_APP_I18N_LOCALE || 'en',
//       locale: __getInitialLocale(),
//       fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
//       messages: __getLocaleMessages()
//     })
//   }
//
//   return instance
// }
//
// getInstance()

export const i18n = new VueI18n({
  // locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  locale: __getInitialLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: __getLocaleMessages()
})
