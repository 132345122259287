<template lang="html">
  <div id="app">
    <div class="container-scroller">
      <gl-header/>
      <router-view></router-view>
      <gl-footer/>
    </div>
  </div>
</template>
<script>
import GlHeader from './components/layout/GlHeader'
import GlFooter from './components/layout/GlFooter'
export default {
  components: {
    GlHeader,
    GlFooter
  }
}
</script>
<style>
</style>
