const pageDescription = 'Our rich products. EventsCalendar - busines events calendar.'
const pageTitle = 'Our rich products'

export default (locale) => {
  return {
    title: pageTitle,
    metaTags: [
      {
        name: 'description', /** 155-160 symbols */
        content: pageDescription
      }, // 150-165 symbols
      {
        property: 'og:title',
        content: pageTitle
      },
      {
        property: 'og:description',
        content: pageDescription
      },
      {
        property: 'og:url',
        content: 'https://geekline.org/' + locale + '/products'
      }
    ],
    jsonld: {
      '@context': 'https://schema.org',
      '@type': 'WebPage',
      '@id': 'https://geekline.org/' + locale + '/products/#webpage',
      'url': 'https://geekline.org/' + locale + '/products',
      'inLanguage': locale,
      'name': 'geekline - products page',
      'isPartOf': {
        '@id': 'https://geekline.org/#website'
      },
      'datePublished': '2020-02-18T01:40:11+01:00',
      'dateModified': '2020-02-20T19:40:11+01:00',
      'description': pageDescription
    }
    // TODO: add ItemList
  }
}
