import devConf from './dev.conf'
import prodConf from './prod.conf'

function __getConfig () {
  if (process.env.NODE_ENV === 'production') {
    return prodConf
  }
  return devConf
  // return prodConf
}
export default __getConfig()
