import baseConf from './base.conf'

export default {
  ...baseConf,
  glapiHost: 'https://geekline.org',
  get glapiBaseUri () { return this.glapiHost + this.glapiRootUri },
  glwebHost: 'https://gloo.geekline.org',
  get glwebValidateUri () { return this.glwebHost + this.glwebValidateSlug },
  imgCloudStoragePreset: 'krfubw5q',
  envTag: 'glprod',
  isProd: true,
  // paypalPartnerClientId: 'ATtg9GkuZkJpxTqUF06EeCPIBwebyk9_LEvvZCEJcjxW7Hg7cpOSwS7wrQPK_1M391ug8F7sUeRdc5Ji'
  // paypalPartnerClientId: 'AXgtiMtuSIYwBZfXo_adUjAf0cMHJWZrmTMQrZBn0DLLgLzCFwlL8R4wz8HSm4AD0i2NVDjBs8yj6u-q'
  paypalPartnerClientId: 'AUl28ocY5Ps3T1Y9ycm32vyOM5SlV0ijL4af1K5UJCz9m59YJR4JR3HQBK1-2tyl1l17K4MKKGv7eb7p',
  stripePlatformPK: 'pk_live_51JDRBCHkVnWbXkMNGK8Uqq98TpyA3HVTVVKkKmGgcLh1s9L7gkPzwfUpBIfKgtPccaWeF4bxiIU3z63UPzwgW8lG00kekFpoze'
}
