const pageDescription = 'We welcome your feedback!'
const pageTitle = 'Contact us or ask a question'

export default (locale) => {
  return {
    title: pageTitle,
    metaTags: [
      {
        name: 'description', /** 155-160 symbols */
        content: pageDescription
      }, // 150-165 symbols
      {
        property: 'og:title',
        content: pageTitle
      },
      {
        property: 'og:description',
        content: pageDescription
      },
      {
        property: 'og:url',
        content: 'https://geekline.org/' + locale + '/contact'
      }
    ],
    jsonld: {
      '@context': 'https://schema.org',
      '@type': 'WebPage',
      '@id': 'https://geekline.org/' + locale + '/contact/#webpage',
      'url': 'https://geekline.org/' + locale + '/contact',
      'inLanguage': locale,
      'name': 'geekline - contact page',
      'isPartOf': {
        '@id': 'https://geekline.org/#website'
      },
      'datePublished': '2020-01-31T01:40:11+01:00',
      'dateModified': '2020-02-19T22:52:11+01:00',
      'description': pageDescription
    }
    // TODO: add ItemList
  }
}
