<template lang="html">
  <footer class="footer mt-auto py-3">
    <div class="container">
      <div class="text-left">
        <ul class="list-unstyled ml-3" style="display: flex; justify-content: space-around;">
          <li>
            <span class="text-muted">Geekline © 2018</span>
          </li>
          <li>
            <b-link :href="$router.localizedRoute('/contact')">{{ $t('header.contact') }}</b-link>
          </li>
          <li>
            <b-link :href="$router.localizedRoute('/')" target="_self">Sign in</b-link>
          </li>
          <li>
            <b-link :href="$router.localizedRoute('/')" target="_self">Join</b-link>
          </li>
          <!-- <li>{{ $i18n.locale }}
          </li> -->
          <li>
            <!-- <b-select v-model="$i18n.locale"
              :options="[{ value: 'en', text: 'English'}, { value: 'de', text: 'Deutsch'}]">
            </b-select> -->
            <b-select v-model="$i18n.locale" :options="localeOptions()" />
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data () {
    return {}
  }, // data
  mounted () {
    // console.log('TRACE-REMOVE: GlFooter.mounted: this.$i18n.locale is: ' + this.$i18n.locale)
  }, // mounted
  methods: {
    localeOptions () {
      const _ret = []
      Object.keys(this.$i18n.messages).forEach((code) => {
        _ret.push({ value: code, text: this.$i18n.messages[code]['self']['name'] })
      })
      return _ret
    }
  } // methods
}
</script>

<style lang="css" scoped>
.footer {
  background-color: var(--info);
}

.footer > .container {
  padding-right: 15px;
  padding-left: 15px;
}

a {
  color: var(--light);
}
.gl-footer {

}
</style>
