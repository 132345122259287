const moduleNamespace = 'account'

const states = {
  GLAPI_ID: 'glapiId',
  GLAPI_VALIDATED: 'glapiValidated',
  ACCNT_DETAILS: 'accntDetails',
  TOKEN: 'token',
  AUTH_INFO: 'authInfo',
  SUBSCRIPTIONS: 'subscriptions',
  INSTANCES: 'instances',
  ORGA_LIST: 'orgaList'
}

const getters = {
  HAS_SUBSCRIPTION: 'hasSubscription',
  HAS_INSTANCE: 'hasInstance',
  HAS_VALID_TOKEN: 'hasValidToken',
  GET_IDENTITY_OBJ: 'getIdentityObject'
}

const mutations = {
  SET_TOKEN: 'setToken',
  SET_AUTH_INFO: 'setAuthInfo',
  SET_GLAPI_ID: 'setGlapiId',
  SET_GLAPI_VALIDATED: 'setGlapiValidated',
  ADD_SUBSCRIPTION: 'addSubscription',
  SET_SUBSCRIPTIONS: 'setSubscriptions',
  REMOVE_INSTANCE: 'removeInstance',
  ADD_INSTANCE: 'addInstance',
  SET_INSTANCES: 'setInstances',
  SET_ORGA_LIST: 'setOrgaList',
  REMOVE_ORGA: 'removeOrga',
  ADD_ORGA: 'addOrga',
  SET_ACCNT_DETAILS: 'setAccntDetails'
}

const actions = {
  LOGIN: 'login',
  UPLOAD_VALIDATION_TOKEN: 'uploadValidationToken',
  SUBSCRIBE: 'subscribe', // appends subscription
  FETCH_SUBSCRIPTIONS: 'fetchSubscriptions',
  SAVE_SUBSCRIPTIONS: 'saveSubscriptions',
  FETCH_INSTANCES: 'fetchInstances',
  CREATE_INSTANCE: 'createInstance',
  SAVE_INSTANCE: 'saveInstance',
  FETCH_ORGA_LIST: 'fetchOrgaList',
  SAVE_ORGA: 'saveOrga',
  ERASE_ORGA: 'eraseOrga',
  SAVE_ACCNT_DETAILS: 'saveAccntDetails'
}

function getNamespacedName (name) {
  return moduleNamespace + '/' + name
}

export { states, getters, mutations, actions, moduleNamespace, getNamespacedName }
