import { states as stateNames
  /* getters as getterNames,
  mutations as mutationNames,
  actions as actionNames */ } from './names'

export const LOCAL_STORAGE_STORE_KEY = 'store.state'

// state
const state = {
  [stateNames.LAST_PERSISTED]: null
}

// getters
const getters = {}

// mutations
const mutations = {}

// actions
const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
