/* the module for the all small domains */
import axios from 'axios'
import config from '@/config'
import * as contentHdlr from '@/handlers/content'
import { subscr, inst, content as contentSvc, commProduct, orders as ordersSvc,
  payments as paymSvc, docs as docsSvc }
  from '@/services/glapi'
import { authHttpHeaders } from '@/utils'
import { states as stateNames,
  mutations as mutationNames,
  actions as actionNames } from './names'
import { getters as accntGetterNames,
  getNamespacedName as getAccntNSpName } from '@/store/modules/account/names'

// TODO: as per issue #63 the default values must be versioned!
const __defaultSubscrTypeCat = [
  { name: 'play', code: 'st-play', title: 'Play - free for ever', feOrder: 1, description: 'A free subscription plan for enthusiasts and interested individuals or enterprises. There are 3 free instances (products) available and 1K instance calls per month.' },
  { name: 'start', code: 'st-start' },
  { name: 'business', code: 'st-business' },
  { name: 'enterprise', code: 'st-enterprise' },
  { name: 'corporate', code: 'st-corporate' }
]

const __defaultInstTypeCat = [
  { name: 'cloudContent', code: 'it-cloudContent', domain: 'content', title: 'Cloud Content', feOrder: 1, description: 'A dinamic cloud persisted content you can upload to the server and embed on your web page.' },
  { name: 'orders', code: 'it-orders', domain: 'orders', title: 'Orders', feOrder: 1, description: 'Order management. Handling of commercial product baskets and customer orders.' }
]

const __defaultContentTypeCat = [
  { name: 'textOnly', code: 'ct-textOnly', description: 'Formatted text content.' },
  { name: 'imageOnly', code: 'ct-imageOnly', description: 'Single image content.' },
  { name: 'commProduct', code: 'ct-commProduct', description: 'Commercial product content.' },
  { name: 'blogPost', code: 'ct-blogPost', description: 'Blog post content.' },
  { name: 'article', code: 'ct-article', description: 'Article content.' },
  { name: 'event', code: 'ct-event', description: 'Event content.' },
  { name: 'donation', code: 'ct-donation', description: 'Daonation content.' },
  { name: 'commercial ad', code: 'ct-commAd', description: 'Commercial ad content.' }
]

const __defaultCommProductCtgyTypeCat = [
  { name: 'Food and Beverage', code: 'cpct-foodBev' }
]

function __getDefaultSubscrTypeCat () {
  // TODO: consider loading from localstorage. consider lastLoaded parameter!
  return __defaultSubscrTypeCat
}

const glapiHttpInst = axios.create({
  baseURL: config.glapiBaseUri,
  timeout: 5000 // TODO: config envs!
})

// state
const state = {
  [stateNames.SUBSCR_TYPE_CAT]: Object.assign({}, __getDefaultSubscrTypeCat()),
  [stateNames.INST_TYPE_CAT]: Object.assign({}, __defaultInstTypeCat),
  // [stateNames.CONTENT_TYPE_CAT]: Object.assign({}, __defaultContentTypeCat),
  [stateNames.CONTENT_TYPE_CAT]: __defaultContentTypeCat.slice(),
  [stateNames.CONTENT]: [],
  [stateNames.COMM_PRODUCT_CTGY_TYPE_CAT]: __defaultCommProductCtgyTypeCat.slice(),
  [stateNames.ORDERS]: [],
  [stateNames.ORDER_DETAILS]: {},
  [stateNames.PAYM_ORDERS]: [],
  [stateNames.DOCS]: []
}

// getters
const getters = {}

// mutations
const mutations = {
  [mutationNames.SET_SUBSCR_TYPE_CAT] (state, stcat) {
    state.subscrTypeCat = stcat
  }, // SET_SUBSCR_TYPE_CAT
  [mutationNames.SET_INST_TYPE_CAT] (state, itcat) {
    console.log('TRACE-REMOVE: store.glapi.SET_INST_TYPE_CAT: itcat is: ', itcat)
    state.instTypeCat = itcat
  }, // SET_INST_TYPE_CAT
  [mutationNames.SET_CONTENT_TYPE_CAT] (state, ctcat) {
    state.contentTypeCat = ctcat
  }, // SET_CONTENT_TYPE_CAT
  [mutationNames.SET_CONTENT] (state, cntntRecords) {
    state.content = cntntRecords
  }, // SET_CONTENT
  [mutationNames.ADD_CONTENT] (state, contentItem) {
    state.content.unshift(contentItem)
  }, // ADD_CONTENT
  [mutationNames.REMOVE_CONTENT] (state, contentItemId) {
    let delIndex = state.content.findIndex((e) => e._id === contentItemId)
    if (delIndex >= 0) {
      state.content.splice(delIndex, 1)
    }
    // TODO: else log problem
  }, // REMOVE_CONTENT
  [mutationNames.SET_COMM_PRODUCT_CTGY_TYPE_CAT] (state, cpctcat) {
    state.commProductCtgyTypeCat = cpctcat
  }, // SET_COMM_PRODUCT_CTGY_TYPE_CAT
  [mutationNames.SET_ORDERS] (state, orders) {
    state.orders = orders
  }, // SET_ORDERS
  [mutationNames.SET_ORDER_DETAILS] (state, orderDetails) {
    state.orderDetails = orderDetails
  }, // SET_ORDER
  [mutationNames.SET_PAYM_ORDERS] (state, paymOrders) {
    state.paymOrders = paymOrders
  }, // SET_PAYM_ORDERS
  [mutationNames.SET_DOCS] (state, docs) {
    state.docs = docs
  } // SET_PAYM_ORDERS
}

// actions
const actions = {
  async [actionNames.FETCH_SUBSCR_TYPE_CAT] ({ commit }) {
    let _subscrTypeCat = glapiHttpInst.get(subscr.getUri(subscr.EndPointsCat.typeCat))
    commit(mutationNames.SET_SUBSCR_TYPE_CAT, _subscrTypeCat)
  }, // FETCH_SUBSCR_TYPE_CAT
  async [actionNames.FETCH_INST_TYPE_CAT] ({ commit }) {
    console.log('TRACE-REMOVE: store.glapi.FETCH_INST_TYPE_CAT!')
    console.log('TRACE-REMOVE: store.glapi.FETCH_INST_TYPE_CAT: inst is: ', inst)
    let _resp = await glapiHttpInst.get(inst.getUri(inst.getEndPointsCat().typeCat))
    console.log('TRACE-REMOVE: store.glapi.FETCH_INST_TYPE_CAT: _resp is: ', _resp)
    if (_resp && _resp.data && _resp.data.status === 'success') {
      commit(mutationNames.SET_INST_TYPE_CAT, _resp.data.payload)
    }
  }, // FETCH_INST_TYPE_CAT
  async [actionNames.FETCH_CONTENT_TYPE_CAT] ({ commit }) {
    let _resp = await glapiHttpInst.get(contentSvc.getUri(contentSvc.getEndPointsCat().typeCat))
    if (_resp && _resp.data && _resp.data.status === 'success') {
      commit(mutationNames.SET_CONTENT_TYPE_CAT, _resp.data.payload)
    }
  }, // FETCH_CONTENT_TYPE_CAT
  async [actionNames.UPLOAD_CONTENT] ({ rootState, rootGetters, commit }, payload) {
    // TODO check login!
    try {
      console.log('TRACE-REMOVE: store.glapi.uploadContent: payload is: ', payload)
      let uploadedItem = await contentHdlr.upload(Object.assign({}, payload,
        rootGetters[getAccntNSpName(accntGetterNames.GET_IDENTITY_OBJ)]),
      authHttpHeaders(rootState.account.token))
      console.log('TRACE-REMOVE: store.glapi.uploadContent: uploadedItem is: ', uploadedItem)
      if (payload['_id']) {
        commit(mutationNames.REMOVE_CONTENT, payload['_id'])
      }
      commit(mutationNames.ADD_CONTENT, uploadedItem)
    } catch (e) {
      console.error('ERROR: store.glapi.uploadContent: e is:', e)
      throw contentSvc.getError(contentSvc.getErrorsCat().networkError)
    }
    // if (_resp.data.status !== 'success') { // TODO: consider new login recommendation!
    //   throw contentSvc.getError(contentSvc.getErrorsCat().serverError)
    // }
  }, // UPLOAD_CONTENT
  async [actionNames.FETCH_CONTENT] ({ rootState, state, commit }) {
    let _resp
    try {
      // console.log('TRACE-REMOVE: store.glapi.fetchContent: rootState.account.glapiId is: ', rootState.account.glapiId)
      const _startTime = performance.now()
      console.log('PERF-REMOVE: store.glapi.FETCH_CONTENT-1: _startTime: ',
        performance.now() - _startTime)
      _resp = await glapiHttpInst.get(
        contentSvc.getUri(contentSvc.getEndPointsCat().contentByAccnt, [rootState.account.glapiId]),
        authHttpHeaders(rootState.account.token))

      console.log('PERF-REMOVE: store.glapi.FETCH_CONTENT-2: ',
        performance.now() - _startTime, performance.now())
      let respData = _resp.data
      if (respData && respData.status === 'success') {
        commit(mutationNames.SET_CONTENT, respData.payload.content)
      }
      console.log('PERF-REMOVE: store.glapi.FETCH_CONTENT-3: ',
        performance.now() - _startTime, performance.now())
    } catch (e) {
      // console.error('ERROR: store.account.fetchContent: ', e)
      // console.log('TRACE-REMOVE: store.account.fetchContent: e.response is: ', e.response)
      // console.log('TRACE-REMOVE: store.account.fetchContent: e.response.status is: ', e.response.status)
      // TODO: issue #50 implicit logout in case of 401! Throw unauthorized error!
      throw contentSvc.getError(contentSvc.getErrorsCat().networkError)
    }

    if (_resp.data.status !== 'success') { // TODO: consider new login recommendation!
      throw contentSvc.getError(contentSvc.getErrorsCat().serverError)
    }
  }, // FETCH_CONTENT
  async [actionNames.ERASE_CONTENT] ({ rootState, state, commit }, payload) {
    let _resp
    try {
      _resp = await glapiHttpInst.delete(
        contentSvc.getUri(contentSvc.getEndPointsCat().content, [payload['_id']]),
        authHttpHeaders(rootState.account.token))
      commit(mutationNames.REMOVE_CONTENT, payload['_id'])
      // console.log('TRACE-REMOVE: store.glapi.ERASE_CONTENT: _resp is', _resp)
    // TODO commit REMOVE_CONTENT
    } catch (e) {
      // console.error('TRACE-REMOVE: ERROR: store.glapi.ERASE_CONTENT: e is: ', e)
      throw contentSvc.getError(contentSvc.getErrorsCat().networkError)
    }
    if (_resp.data.status !== 'success') { // TODO: consider new login recommendation!
      throw contentSvc.getError(contentSvc.getErrorsCat().serverError)
    }
  }, // ERASE_CONTENT
  async [actionNames.FETCH_COMM_PRODUCT_CTGY_TYPE_CAT] ({ commit }) {
    let _resp
    try {
      const _startTime = performance.now()
      console.log('PERF-REMOVE: store.glapi.FETCH_COMM_PRODUCT_CTGY_TYPE_CAT-1: ',
        performance.now() - _startTime, performance.now())
      _resp = await glapiHttpInst.get(
        commProduct.getUri(commProduct.getEndPointsCat().ctgyTypeCat))

      console.log('PERF-REMOVE: store.glapi.FETCH_COMM_PRODUCT_CTGY_TYPE_CAT-2: ',
        performance.now() - _startTime, performance.now())
      let respData = _resp.data
      if (respData && respData.status === 'success') {
        commit(mutationNames.SET_COMM_PRODUCT_CTGY_TYPE_CAT, respData.data)
      }
      console.log('PERF-REMOVE: store.glapi.FETCH_COMM_PRODUCT_CTGY_TYPE_CAT-3: ',
        performance.now() - _startTime, performance.now())
    } catch (e) {
      throw commProduct.getError(commProduct.getErrorsCat().networkError)
    }
    // TODO: consider silent fail, just notification flag set!
    if (_resp.data.status !== 'success') { // TODO: consider new login recommendation!
      throw contentSvc.getError(contentSvc.getErrorsCat().serverError)
    }
  }, // FETCH_COMM_PRODUCT_CTGY_TYPE_CAT
  async [actionNames.FETCH_ORDERS] ({ rootState, commit }, payload) {
    let _resp
    try {
      _resp = await glapiHttpInst.get(
        ordersSvc.getUri(ordersSvc.getEndPointsCat().orders, [payload]),
        authHttpHeaders(rootState.account.token))

      let respData = _resp.data
      if (respData && respData.status === 'success') {
        commit(mutationNames.SET_ORDERS, respData.payload.orders)
      }
    } catch (e) {
      console.log('TRACE-REMOVE: store.glapi.fetchOrders: Exception is: ', e)
      throw ordersSvc.getError(ordersSvc.getErrorsCat().networkError)
    }
    // TODO: consider silent fail, just notification flag set!
    if (_resp.data.status !== 'success') { // TODO: consider new login recommendation!
      throw ordersSvc.getError(ordersSvc.getErrorsCat().serverError)
    }
  }, // FETCH_ORDERS
  async [actionNames.FETCH_ORDER_DETAILS] ({ rootState, commit }, payload) {
    let _resp
    try {
      _resp = await glapiHttpInst.get(
        ordersSvc.getUri(ordersSvc.getEndPointsCat().orderDetails, payload),
        authHttpHeaders(rootState.account.token))

      let respData = _resp.data
      if (respData && respData.status === 'success') {
        console.log('TRACE-REMOVE: store.glapi.fetchOrderDetails: payload is: ', respData.payload)
        commit(mutationNames.SET_ORDER_DETAILS, respData.payload.orderDetails)
      }
    } catch (e) {
      console.log('TRACE-REMOVE: store.glapi.fetchOrderDetails: Exception is: ', e)
      throw ordersSvc.getError(ordersSvc.getErrorsCat().networkError)
    }
    // TODO: consider silent fail, just notification flag set!
    if (_resp.data.status !== 'success') { // TODO: consider new login recommendation!
      throw ordersSvc.getError(ordersSvc.getErrorsCat().serverError)
    }
  }, // FETCH_ORDER_DETAILS
  async [actionNames.UPLOAD_ORDER_DETAILS] ({ rootState, rootGetters, commit }, payload) {
    // TODO check login!
    // TODO check payload._id.key and this.orderDetails.key are equal!
    console.log('TRACE-REMOVE: store.glapi.uploadOrderDetails: called!')
    try {
      // let uploadedItem = await contentHdlr.upload(Object.assign({},
      //   payload,
      //   rootGetters[getAccntNSpName(accntGetterNames.GET_IDENTITY_OBJ)]), authHttpHeaders(rootState.account.token))
      // console.log('TRACE-REMOVE: store.glapi.uploadOrderDetails: uploadedItem is: ', uploadedItem)
      console.log('TRACE-REMOVE: store.glapi.uploadOrderDetails: payload is: ', payload)
      console.log('TRACE-REMOVE: store.glapi.uploadOrderDetails: authHttpHeaders is: ', authHttpHeaders(rootState.account.token))
      const _resp = await glapiHttpInst.post(ordersSvc.getUri(ordersSvc.getEndPointsCat().ordersOrder, payload.params),
        payload.orderDetails, authHttpHeaders(rootState.account.token))

      if (_resp.data.status === 'success') {
        // commit(mutationNames.SET_ORDER_DETAILS, payload)
        if (this.state.glapi.orderDetails.confirmed !== payload.confirmed) {
          this.state.glapi.orderDetails.confirmed = payload.confirmed
        }
        if (this.state.glapi.orderDetails.sent !== payload.sent) {
          this.state.glapi.orderDetails.sent = payload.sent
        }
      }
      // if (payload['_id']) {
      //   commit(mutationNames.REMOVE_CONTENT, payload['_id'])
      // }
      // commit(mutationNames.ADD_CONTENT, uploadedItem)
    } catch (e) {
      console.error('ERROR: store.glapi.uploadOrderDetails: e is:', e)
      throw contentSvc.getError(ordersSvc.getErrorsCat().networkError)
    }
    // if (_resp.data.status !== 'success') { // TODO: consider new login recommendation!
    //   throw contentSvc.getError(contentSvc.getErrorsCat().serverError)
    // }
  }, // UPLOAD_ORDER_DETAILS
  async [actionNames.CREATE_ONBOARDING] ({ rootState }, payload) {
    let _resp
    try {
      console.log('TRACE-REMOVE: store.glapi.createOnboarding: payload is: ', payload)
      console.log('TRACE-REMOVE: store.glapi.createOnboarding: authHttpHeaders is: ', authHttpHeaders(rootState.account.token))
      _resp = await glapiHttpInst.post(
        paymSvc.getUri(paymSvc.getEndPointsCat().onboarding, [payload.instKey, payload.pit]),
        '', authHttpHeaders(rootState.account.token))
    } catch (e) {
      console.error('ERROR: store.glapi.createOnboarding: e is:', e)
      throw contentSvc.getError(paymSvc.getErrorsCat().networkError)
    }
    if (_resp.data.status !== 'success') {
      throw contentSvc.getError(paymSvc.getErrorsCat().serverError)
    }
  }, // CREATE_ONBOARDING
  async [actionNames.UPDATE_ONBOARDING_COMPLETE] ({ rootState }, payload) {
    let _resp
    try {
      console.log('TRACE-REMOVE: store.glapi.updateOnboardingComplete: payload is: ', payload)
      const instKey = payload.instKey
      delete payload.instKey
      const pit = payload.pit
      delete payload.pit
      _resp = await glapiHttpInst.put(
        paymSvc.getUri(paymSvc.getEndPointsCat().onboarding, [instKey, pit]),
        payload, authHttpHeaders(rootState.account.token))
    } catch (e) {
      console.error('ERROR: store.glapi.updateOnboardingComplete: e is:', e)
      throw contentSvc.getError(paymSvc.getErrorsCat().networkError)
    }
    if (_resp.data.status !== 'success') {
      throw contentSvc.getError(paymSvc.getErrorsCat().serverError)
    }
  }, // UPDATE_ONBOARDING_COMPLETE
  [actionNames.CREATE_PAYMENT_ORDER] ({ rootState }, payload) {
    return glapiHttpInst.post(
      paymSvc.getUri(paymSvc.getEndPointsCat().paymentOrder, [payload.instKey, payload.pit]),
      { amount: payload.amount }, authHttpHeaders(rootState.account.token))
  }, // CREATE_PAYMENT_ORDER
  [actionNames.UPDATE_PAYMENT_ORDER] ({ rootState }, payload) {
    return glapiHttpInst.put(
      paymSvc.getUri(paymSvc.getEndPointsCat().paymentOrder, [payload.instKey, payload.pit]),
      { orderId: payload.orderId }, authHttpHeaders(rootState.account.token))
  }, // UPDATE_PAYMENT_ORDER
  async [actionNames.FETCH_PAYM_ORDERS] ({ rootState, commit }, payload) {
    let _resp
    try {
      _resp = await glapiHttpInst.get(
        paymSvc.getUri(paymSvc.getEndPointsCat().paymentOrder, payload),
        authHttpHeaders(rootState.account.token))

      let respData = _resp.data
      if (respData && respData.status === 'success') {
        commit(mutationNames.SET_PAYM_ORDERS, respData.payload.paymOrders)
      }
    } catch (e) {
      console.log('TRACE-REMOVE: store.glapi.fetchOrders: Exception is: ', e)
      throw ordersSvc.getError(ordersSvc.getErrorsCat().networkError)
    }
    // TODO: consider silent fail, just notification flag set!
    if (_resp.data.status !== 'success') { // TODO: consider new login recommendation!
      throw ordersSvc.getError(ordersSvc.getErrorsCat().serverError)
    }
  }, // FETCH_PAYM_ORDERS
  async [actionNames.FETCH_DOCS] ({ rootState, commit }, payload) {
    let _resp
    try {
      _resp = await glapiHttpInst.get(
        docsSvc.getUri(docsSvc.getEndPointsCat().allByInst, [payload]),
        authHttpHeaders(rootState.account.token))

      let respData = _resp.data
      if (respData && respData.status === 'success') {
        commit(mutationNames.SET_DOCS, respData.payload.docs)
      }
    } catch (e) {
      console.log('TRACE-REMOVE: store.glapi.fetchDocs: Exception is: ', e)
      throw docsSvc.getError(docsSvc.getErrorsCat().networkError)
    }
    // TODO: consider silent fail, just notification flag set!
    if (_resp.data.status !== 'success') { // TODO: consider new login recommendation!
      throw docsSvc.getError(docsSvc.getErrorsCat().serverError)
    }
  } // FETCH_DOCS
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
