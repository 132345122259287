import baseConf from './base.conf'

export default {
  ...baseConf,
  glapiHost: 'http://localhost:3010',
  get glapiBaseUri () { return this.glapiHost + this.glapiRootUri },
  glwebHost: 'http://localhost:8080',
  get glwebValidateUri () { return this.glwebHost + this.glwebValidateSlug },
  imgCloudStoragePreset: 'yyfkd3ql',
  envTag: 'gldev',
  isProd: false,
  paypalPartnerClientId: 'Abg4xkb3Cv0smWnaCEt5hOvB-iZTamB0KyhqRGk8excO4pkiHiS3XVoxxMMZ-1nKTHKvWVqeYOTl5g4v',
  stripePlatformPK: 'pk_test_51JDRBCHkVnWbXkMNkOdVGRMrazDeKeqSBhwI5FYEXqw1xJv8vXDd1pIFoveb8aAr6TPmgB3H13tIJgzgfO4hVwqE00zgR9w3Ij'
}
