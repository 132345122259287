import { isUndefinedOrNull, catalogify, replaceParams } from '@/utils'

export const AccntEndPoints = {
  login: '/login',
  validate: '/validate/:0',
  subscribe: '/subscribe',
  subscriptions: '/subscriptions',
  createInstance: '/createInstance',
  saveInstance: '/saveInstance',
  instances: '/instances',
  orgas: '/orgas',
  saveOrgaCreated: '/orgaCreated',
  orga: '/:0/orga/:1',
  accntDetails: '/:0/accntDetails'
}

const AccntErrors = {
  name: 'Account Error',
  serverError: { msg: 'Internal server error!' },
  networkError: { msg: 'Network error!' },
  noValidToken: { msg: 'You need to be logged in for this operation!' },
  noIdentification: { msg: 'No identification for the user is available!' },
  subscrAvailable: { msg: 'This subscription is already available! Only one subscription type per account is allowed!' },
  instAvailable: { msg: 'This instance is already available! Only one instance type per account is allowed!' }
}

export const accnt = {
  baseUri: '/accnt',
  getErrorsCat () {
    return catalogify(AccntErrors)
  }, // getErrorsCat
  getError (err) {
    var retError = new Error(AccntErrors[err].msg)
    retError.name = AccntErrors.name
    return retError
  }, // getError
  getApiError (errMsg) {
    var retError = new Error(errMsg)
    retError.name = 'Api - ' + AccntErrors.name
    return retError
  }, // getApiError
  getEndPointsCat () {
    return catalogify(AccntEndPoints)
  }, // getEndPointsCat
  getUri (endp, accId) {
    if (isUndefinedOrNull(accId)) {
      return this.baseUri + AccntEndPoints[endp]
    }
    return this.baseUri + '/' + accId + AccntEndPoints[endp]
  }, // getUri
  getParamUri (endp, params) {
    return replaceParams(params, this.baseUri + AccntEndPoints[endp])
  } // getParamUri
}

export const SubscrEndPointsCat = { /* End points catalogue */
  typeCat: 'typeCat'
}

export const subscr = {
  baseUri: '/subscr',
  endPoints: {
    [SubscrEndPointsCat.typeCat]: '/typeCat'
  },
  getEndPointsCat () {
    return SubscrEndPointsCat
  }, // getEndPointsCat
  getUri (endp) {
    return this.baseUri + this.endPoints[endp]
  } // getUri
}

export const InstEndPointsCat = { /* End points catalogue */
  typeCat: 'typeCat'
}

export const inst = {
  baseUri: '/inst',
  endPoints: {
    [InstEndPointsCat.typeCat]: '/typeCat'
  },
  getEndPointsCat () {
    return InstEndPointsCat
  }, // getEndPointsCat
  getUri (endp) {
    return this.baseUri + this.endPoints[endp]
  } // getUri
}

const ContentEndPoints = {
  typeCat: '/typeCat',
  content: '/:0',
  contentByAccnt: '/accnt/:0',
  upload: '/upload',
  uploadPdf: '/pdfUploads'
}

const ContentErrors = {
  name: 'Content Error',
  serverError: { msg: 'Internal server error!' },
  networkError: { msg: 'Network error!' }
}

export const content = {
  baseUri: '/content',
  getErrorsCat () {
    return catalogify(ContentErrors)
  }, // getErrorsCat
  getError (err) {
    var retError = new Error(ContentErrors[err].msg)
    retError.name = ContentErrors.name
    return retError
  }, // getError
  getUri (endp, payload) {
    var _ret = this.baseUri + ContentEndPoints[endp]
    var i = 0
    if (payload) {
      payload.forEach(e => {
        const _repl = ':' + i++
        _ret = _ret.replace(_repl, e)
      })
    }
    return _ret
  }, // getUri
  getEndPointsCat () {
    return catalogify(ContentEndPoints)
  }
}

const CommProductEndPoints = {
  ctgyTypeCat: '/ctgyTypeCat'
}

const CommProductErrors = {
  name: 'Commercial Product',
  serverError: { msg: 'Internal server error!' },
  networkError: { msg: 'Network error!' }
}

export const commProduct = {
  baseUri: '/commProduct',
  getErrorsCat () {
    return catalogify(CommProductErrors)
  }, // getErrorsCat
  getError (err) {
    var retError = new Error(CommProductErrors[err].msg)
    retError.name = CommProductErrors.name
    return retError
  }, // getError
  getUri (endp, payload) {
    var _ret = this.baseUri + CommProductEndPoints[endp]
    var i = 0
    if (payload) {
      payload.forEach(e => {
        const _repl = ':' + i++
        _ret = _ret.replace(_repl, e)
      })
    }
    return _ret
  }, // getUri
  getEndPointsCat () {
    return catalogify(CommProductEndPoints)
  }
}

const OrdersEndPoints = {
  orders: '/:0',
  ordersOrder: '/:0/order/:1',
  orderDetails: '/:0/order/:1/details'
}

const OrdersErrors = {
  name: 'Orders',
  serverError: { msg: 'Internal server error!' },
  networkError: { msg: 'Network error!' }
}

export const orders = {
  baseUri: '/orders',
  getErrorsCat () {
    return catalogify(OrdersErrors)
  }, // getErrorsCat
  getError (err) {
    var retError = new Error(OrdersErrors[err].msg)
    retError.name = OrdersErrors.name
    return retError
  }, // getError
  getUri (endp, payload) {
    return replaceParams(payload, this.baseUri + OrdersEndPoints[endp])
  }, // getUri
  getEndPointsCat () {
    return catalogify(OrdersEndPoints)
  }
}

const PaymentsEndPoints = {
  onboarding: '/:0/onboarding/:1',
  paymentOrder: '/:0/paymentOrder/:1'
}

const PaymentsErrors = {
  name: 'Payments',
  serverError: { msg: 'Internal server error!' },
  networkError: { msg: 'Network error!' }
}

export const payments = {
  baseUri: '/paym',
  getErrorsCat () {
    return catalogify(PaymentsErrors)
  }, // getErrorsCat
  getError (err) {
    var retError = new Error(PaymentsErrors[err].msg)
    retError.name = PaymentsErrors.name
    return retError
  }, // getError
  getUri (endp, payload) {
    return replaceParams(payload, this.baseUri + PaymentsEndPoints[endp])
  }, // getUri
  getEndPointsCat () {
    return catalogify(PaymentsEndPoints)
  }
}

const DocsEndPoints = {
  allByInst: '/:0'
}

const DocsErrors = {
  name: 'Docs',
  serverError: { msg: 'Internal server error!' },
  networkError: { msg: 'Network error!' }
}

export const docs = {
  baseUri: '/docs',
  getErrorsCat () {
    return catalogify(DocsErrors)
  }, // getErrorsCat
  getError (err) {
    var retError = new Error(DocsErrors[err].msg)
    retError.name = DocsErrors.name
    return retError
  }, // getError
  getUri (endp, payload) {
    return replaceParams(payload, this.baseUri + DocsEndPoints[endp])
  }, // getUri
  getEndPointsCat () {
    return catalogify(DocsEndPoints)
  }
}
