/* the module for the accounts domain */
import axios from 'axios'
import config from '@/config'
import { accnt } from '@/services/glapi'
import { isUndefinedOrNull } from '@/utils'
import { LOCAL_STORAGE_STORE_KEY } from '@/store/modules/system'
import { states as stateNames,
  moduleNamespace as accntNs,
  getters as getterNames,
  mutations as mutationNames,
  actions as actionNames } from './names'

const __defaultState = {
  [stateNames.TOKEN]: null,
  [stateNames.GLAPI_ID]: null,
  [stateNames.GLAPI_VALIDATED]: false,
  [stateNames.ACCNT_DETAILS]: {},
  [stateNames.AUTH_INFO]: null,
  [stateNames.SUBSCRIPTIONS]: [],
  [stateNames.INSTANCES]: [],
  [stateNames.ORGA_LIST]: []
}

const authHttpHeaders = (token) => { return { headers: { Authorization: `Bearer ${token}` } } }

// console.log('TRACE-REMOVE: store.account: config.glapiBaseUri is: ', config.glapiBaseUri)
const glapiHttpInst = axios.create({
  // baseURL: 'http://localhost:3010/api/v0.1', // TODO: config envs!
  baseURL: config.glapiBaseUri,
  timeout: 5000 // TODO: config envs!
})

function __createIdentityObj (state) {
  var idObj = {}
  if (state.glapiId) {
    idObj['accountId'] = state.glapiId
  } else if (state.authInfo && state.authInfo.email) {
    idObj['email'] = state.authInfo.email
  } else {
    // TODO: consider try login, else logout
    throw accnt.getError(accnt.getErrorsCat().noIdentification)
  }
  return idObj
}

function __initState () {
  let _rootState = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_STORE_KEY + '_' + accntNs))
  // TODO: check the system.lastPersisted and decide if the persisted state is stale
  if (!isUndefinedOrNull(_rootState)) {
    // return _rootState.account
    return _rootState
  }
  return __defaultState
}

// state
const state = __initState()

// getters
const getters = {
  [getterNames.HAS_SUBSCRIPTION]: state => subscrTypeCode => state.subscriptions.some(val => val.subscrTypeCode === subscrTypeCode),
  [getterNames.HAS_INSTANCE]: state => instTypeCode => state.instances.some(val => val.instTypeCode === instTypeCode),
  [getterNames.HAS_VALID_TOKEN]: (state) => {
    return state.authInfo.exp * 1000 > Date.now()
  },
  [getterNames.GET_IDENTITY_OBJ]: (state) => {
    return __createIdentityObj(state)
  }
}

// mutations
const mutations = {
  [mutationNames.SET_TOKEN] (state, token) {
    state.token = token
  },
  [mutationNames.SET_AUTH_INFO] (state, authInfo) {
    state.authInfo = authInfo
  },
  [mutationNames.SET_GLAPI_ID] (state, glapiId) {
    state.glapiId = glapiId
  },
  [mutationNames.SET_GLAPI_VALIDATED] (state, glapiValidated) {
    state.glapiValidated = glapiValidated
  },
  [mutationNames.ADD_SUBSCRIPTION] (state, subscr) {
    state.subscriptions.push(subscr)
  },
  [mutationNames.SET_SUBSCRIPTIONS] (state, subscrs) {
    state.subscriptions = subscrs
  },
  [mutationNames.REMOVE_INSTANCE] (state, instKey) {
    let delIndex = state.instances.findIndex(e => e.instKey === instKey)
    if (delIndex >= 0) {
      state.instances.splice(delIndex, 1)
    } else {
      // TODO: Warning!!!
    }
  },
  [mutationNames.ADD_INSTANCE] (state, inst) {
    state.instances.push(inst)
  },
  [mutationNames.SET_INSTANCES] (state, insts) {
    state.instances = insts
  },
  [mutationNames.SET_ORGA_LIST] (state, orgaList) {
    state.orgaList = orgaList
  },
  [mutationNames.ADD_ORGA] (state, orga) {
    state.orgaList.unshift(orga)
  }, // ADD_ORGA
  [mutationNames.REMOVE_ORGA] (state, orgaKey) {
    let delIndex = state.orgaList.findIndex((e) => e.key === orgaKey)
    if (delIndex >= 0) {
      state.orgaList.splice(delIndex, 1)
    }
  }, // REMOVE_ORGA
  [mutationNames.SET_ACCNT_DETAILS] (state, accntDetails) {
    // state.instances = insts
    console.log('TRACE-REMOVE: store.account.setAccntDetails: accntDetails is: ',
      accntDetails)
    state.accntDetails = Object.assign(state.accntDetails || {}, accntDetails)
  } // SET_ACCNT_DETAILS
}

// actions
const actions = {
  async [actionNames.LOGIN] ({ commit }, payload) {
    // /* eslint-disable-next-line */
    // let unwrapClaims = ({ nickname, name, picture, updated_at, email, email_verified, iss, sub, aud }) =>
    //   ({ nickname, name, picture, updated_at, email, email_verified, iss, sub, aud })

    commit(mutationNames.SET_TOKEN, payload.token)
    commit(mutationNames.SET_AUTH_INFO, payload.authInfo)
    let _resp = await glapiHttpInst.post(accnt.getUri(accnt.getEndPointsCat().login), // eslint-disable-line no-unused-vars
      payload.authInfo,
      authHttpHeaders(payload.token))

    if (_resp['data']['status'] === 'success') {
      commit(mutationNames.SET_GLAPI_ID, _resp['data']['glapiId'])
      commit(mutationNames.SET_GLAPI_VALIDATED, _resp['data']['glapiValidated'])
      console.log('TRACE-REMOVE: store.account.login: _resp.data.authorName is: ',
        _resp['data']['authorName'])
      if (_resp['data']['authorName']) {
        commit(mutationNames.SET_ACCNT_DETAILS, { authorName: _resp['data']['authorName'] })
      }
      // commit(mutationNames.SET_AUTH_INFO, _resp['data']['authInfo'])
    }
  }, // login
  async [actionNames.UPLOAD_VALIDATION_TOKEN] ({ state, commit }, vtoken) {
    let resp
    try {
      console.log('TRACE-REMOVE: store.accnt: vtoken is: ', vtoken)
      resp = await glapiHttpInst.get(
        accnt.getParamUri(accnt.getEndPointsCat().validate, [vtoken]))
    } catch (e) {
      // TODO beware of 401!
      // console.error('ERROR: store.account.FETCH_INSTANCES: ', e)
      throw accnt.getError(accnt.getErrorsCat().networkError)
    }

    if (resp.data.status !== 'success') { // TODO: consider new login recommendation!
      throw accnt.getError(accnt.getErrorsCat().serverError)
    }
  }, // UPLOAD_VALIDATION_TOKEN
  async [actionNames.SUBSCRIBE] ({ state, commit, getters }, subscrCode) {
    if (!getters.hasValidToken) { // TODO: in case of expired token try refresh
      throw accnt.getError(accnt.getErrorsCat().noValidToken)
    }

    if (getters.hasSubscription(subscrCode)) {
      throw accnt.getError(accnt.getErrorsCat().subscrAvailable)
    }

    let subscrReq = __createIdentityObj(state)
    subscrReq['subscrTypeCode'] = subscrCode
    var resp
    try {
      resp = await glapiHttpInst.post(accnt.getUri(accnt.getEndPointsCat().subscribe),
        subscrReq, authHttpHeaders(state.token))
      if (resp.data.status === 'success') {
        commit(mutationNames.ADD_SUBSCRIPTION, { subscrId: resp.data.payload.subscrId, subscrTypeCode: subscrCode })
      }
    } catch (e) {
      // console.error('ERROR: store.account.subscribe: ', e)
      // TODO: consider error trakcing
      throw accnt.getError(accnt.getErrorsCat().networkError)
    }

    if (resp.data.status !== 'success') { // TODO: consider new login recommendation!
      throw accnt.getError(accnt.getErrorsCat().serverError)
    }
  }, // subscribe
  async [actionNames.FETCH_SUBSCRIPTIONS] ({ state, commit }) {
    let resp
    try {
      resp = await glapiHttpInst.get(
        accnt.getUri(accnt.getEndPointsCat().subscriptions, state.glapiId),
        authHttpHeaders(state.token))

      let respData = resp.data
      if (respData && respData.status === 'success') {
        let mappedSubscriptions = []
        if (respData.payload.subscriptions.length > 0) {
          mappedSubscriptions = respData.payload.subscriptions
            .map(e => { return { subscrId: e._id, subscrTypeCode: e.subscrTypeCode, created: e.created } })
        }
        commit(mutationNames.SET_SUBSCRIPTIONS, mappedSubscriptions)
      }
    } catch (e) {
      // console.error('ERROR: store.account.getchSubscriptions: ', e)
      throw accnt.getError(accnt.getErrorsCat().networkError)
    }

    if (resp.data.status !== 'success') { // TODO: consider new login recommendation!
      throw accnt.getError(accnt.getErrorsCat().serverError)
    }
  }, // fetchSubscriptions
  async [actionNames.CREATE_INSTANCE] ({ rootGetters, state, getters, commit }, instCode) {
    // TODO: not working! implement appropriate log out (issue #50)
    // if (!rootGetters[accGetNamespacedName(accGetterNames.HAS_VALID_TOKEN)]) { // TODO: in case of expired token try refresh
    //   throw accnt.getError(accnt.getErrorsCat().noValidToken)
    // }

    console.log('DEBUG: store.account: __defaultState is: ', __defaultState)
    console.log('DEBUG: store.account: state.instances is: ', state.instances)
    if (getters.hasInstance(instCode)) {
      throw accnt.getError(accnt.getErrorsCat().instAvailable)
    }

    let crInstReq = __createIdentityObj(state)
    crInstReq['instTypeCode'] = instCode
    let resp
    let _ex = null
    try {
      resp = await glapiHttpInst.post(accnt.getUri(accnt.getEndPointsCat().createInstance),
        crInstReq, authHttpHeaders(state.token))
      if (resp.data.status === 'success') {
        commit(mutationNames.ADD_INSTANCE, { instId: resp.data.payload.instId, instTypeCode: instCode })
      } else if (resp.data.status === 'error') {
        _ex = accnt.getApiError(resp.data.payload.msg)
      } else {
        // TODO consider registering internal error
      }
    } catch (e) {
      throw accnt.getError(accnt.getErrorsCat().networkError)
    }
    if (_ex !== null) {
      throw _ex
    }
  }, // CREATE_INSTANCE
  async [actionNames.SAVE_INSTANCE] ({ rootGetters, state, getters, commit }, payload) {
    let resp
    let _ex = null
    try {
      resp = await glapiHttpInst.put(accnt.getUri(accnt.getEndPointsCat().saveInstance),
        payload, authHttpHeaders(state.token))
      if (resp.data.status === 'success') {
        commit(mutationNames.REMOVE_INSTANCE, payload['instKey'])
        commit(mutationNames.ADD_INSTANCE, resp.data.payload.inst)
      } else if (resp.data.status === 'error') {
        _ex = accnt.getApiError(resp.data.payload.msg)
      } else {
        // TODO consider registering internal error
      }
    } catch (e) {
      throw accnt.getError(accnt.getErrorsCat().networkError)
    }
    if (_ex !== null) {
      throw _ex
    }
  }, // SAVE_INSTANCE
  async [actionNames.FETCH_INSTANCES] ({ state, commit }) {
    let resp
    try {
      resp = await glapiHttpInst.get(accnt.getUri(accnt.getEndPointsCat().instances, state.glapiId),
        authHttpHeaders(state.token))

      // console.log('TRACE-REMOVE: store.account.FETCH_INSTANCES: resp is: ', resp)
      let respData = resp.data
      if (respData && respData.status === 'success') {
        commit(mutationNames.SET_INSTANCES, respData.payload.instances)
      }
    } catch (e) {
      // TODO beware of 401!
      // console.error('ERROR: store.account.FETCH_INSTANCES: ', e)
      throw accnt.getError(accnt.getErrorsCat().networkError)
    }

    if (resp.data.status !== 'success') { // TODO: consider new login recommendation!
      throw accnt.getError(accnt.getErrorsCat().serverError)
    }
  }, // fetchInstances
  async [actionNames.FETCH_ORGA_LIST] ({ state, commit }) {
    let resp
    try {
      // resp = await glapiHttpInst.get(
      //   accnt.getUri(accnt.getEndPointsCat().orgas, state.glapiId),
      //   authHttpHeaders(state.token))
      resp = await glapiHttpInst.get(
        accnt.getParamUri(accnt.getEndPointsCat().orga, [state.glapiId, 'all']),
        authHttpHeaders(state.token))

      let _respData = resp.data
      if (_respData && _respData.status === 'success') {
        commit(mutationNames.SET_ORGA_LIST, _respData.payload.orga)
      }
    } catch (e) {
      // TODO beware of 401!
      // console.error('ERROR: store.account.fetchOrgas: ', e)
      throw accnt.getError(accnt.getErrorsCat().networkError)
    }

    if (resp.data.status !== 'success') { // TODO: consider new login recommendation!
      throw accnt.getError(accnt.getErrorsCat().serverError)
    }
  }, // fetchOrgaList
  async [actionNames.SAVE_ORGA] ({ rootGetters, state, getters, commit }, payload) {
    console.log('TRACE-REMOVE: store.account.saveOrga called!')
    // let _req = __createIdentityObj(state)
    // _req['orga'] = payload
    let _req = Object.assign(payload, __createIdentityObj(state))
    let _resp
    let _ex = null
    try {
      // _resp = await glapiHttpInst.post(
      //   accnt.getUri(accnt.getEndPointsCat().saveOrgaCreated, state.glapiId),
      //   _req, authHttpHeaders(state.token))
      // let _params
      if (payload.key) {
        _resp = await glapiHttpInst.put(
          accnt.getParamUri(accnt.getEndPointsCat().orga, [state.glapiId, payload.key]),
          _req, authHttpHeaders(state.token))
      } else {
        _resp = await glapiHttpInst.post(
          accnt.getParamUri(accnt.getEndPointsCat().orga, [state.glapiId, 'new']),
          _req, authHttpHeaders(state.token))
      }

      if (_resp.data && _resp.data.status === 'success') {
        if (payload['key']) {
          commit(mutationNames.REMOVE_ORGA, payload['key'])
        }
        commit(mutationNames.ADD_ORGA, _resp.data.payload.orga)
      } else if (_resp.data.status === 'error') {
        _ex = accnt.getApiError(_resp.data.payload.msg)
      } else {
        // TODO consider registering internal error
      }
    } catch (e) {
      // console.log('ERROR-REMOVE: storage.account.saveOrga: error is: ', e)
      throw accnt.getError(accnt.getErrorsCat().networkError)
    }
    if (_ex !== null) {
      throw _ex
    }
  }, // saveOrga
  async [actionNames.ERASE_ORGA] ({ rootState, state, commit }, payload) {
    console.log('TRACE-REMOVE: store.account.eraseOrga called!')
    let _resp
    try {
      _resp = await glapiHttpInst.delete(
        accnt.getParamUri(accnt.getEndPointsCat().orga, [state.glapiId, payload.key]),
        authHttpHeaders(rootState.account.token))
      commit(mutationNames.REMOVE_ORGA, payload.key)
      // console.log('TRACE-REMOVE: store.glapi.ERASE_CONTENT: _resp is', _resp)
    // TODO commit REMOVE_CONTENT
    } catch (e) {
      // console.error('TRACE-REMOVE: ERROR: store.glapi.ERASE_CONTENT: e is: ', e)
      throw accnt.getError(accnt.getErrorsCat().networkError)
    }
    if (_resp.data.status !== 'success') { // TODO: consider new login recommendation!
      throw accnt.getError(accnt.getErrorsCat().serverError)
    }
  }, // eraseOrga
  async [actionNames.SAVE_ACCNT_DETAILS] ({ rootGetters, state, getters, commit },
    payload) {
    console.log('TRACE-REMOVE: store.account.saveAccntDetails called!')
    // let _req = Object.assign(payload, __createIdentityObj(state))
    let _resp
    let _ex = null
    try {
      // TODO: filter the allowed fields!
      _resp = await glapiHttpInst.put(
        accnt.getParamUri(accnt.getEndPointsCat().accntDetails, [state.glapiId]),
        payload, authHttpHeaders(state.token))

      if (_resp.data && _resp.data.status === 'success') {
        // commit(mutationNames.SET_ACCNT_DETAILS, _resp.data.payload.accntDetails)
        commit(mutationNames.SET_ACCNT_DETAILS, payload)
      } else if (_resp.data.status === 'error') {
        _ex = accnt.getApiError(_resp.data.payload.msg)
      } else {
        // TODO consider registering internal error
      }
    } catch (e) {
      // console.log('ERROR-REMOVE: storage.account.saveOrga: error is: ', e)
      throw accnt.getError(accnt.getErrorsCat().networkError)
    }
    if (_ex !== null) {
      throw _ex
    }
  } // saveAuthInfo
} // actions

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
