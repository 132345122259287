<template>
  <div class="home col">
    <b-card
      overlay
      img-src="../assets/home/screens-aragon-1.5-1280x594.jpg"
      img-alt="Header img"
      img-width="1280"
      img-height="100%"
      text-variant="white"
      title="Geekline - creativity &amp; innovation first!"
      sub-title="Passion to innovate!"
      style="text-align: center;">
    </b-card>

    <div style="text-align: center; font-size: 28px;">
      <strong>Rich Services and Products Palette</strong>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-6" style="display: flex; align-items: stretch;">
          <b-card
            header-class="text-center"
            title="Cloud solutions"
            style="background-color: lightblue; margin: 2px;">
            <template v-slot:header>
              <b-icon icon="cloud-check-fill" font-scale="2" style="font-size: 16px;"></b-icon>
            </template>
            <p>
              Open Shift, Heroku, Python anywhere, Amazon S3, etc. Cloud server solutions taylored exactly for your needs. High availability and high scalability on demand.
            </p>
            <template v-slot:footer>
              <center>
                <b-button>Read more</b-button>
              </center>
            </template>
          </b-card>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6" style="display: flex; align-items: stretch;">
          <b-card
            header-class="text-center"
            title="Responsive design web site"
            style="background-color: lightblue; margin: 2px;">
            <template v-slot:header>
              <b-icon icon="code-square" font-scale="2" style="font-size: 16px;"></b-icon>
            </template>
            <p>
              For our customers we offer a starter package with responsive website, based on our free HTML5 templates. Banners, Analytics and Ade inclusive.
            </p>
            <template v-slot:footer>
              <center>
                <b-button>Read more</b-button>
              </center>
            </template>
          </b-card>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6" style="display: flex; align-items: stretch;">
          <b-card
            header-class="text-center"
            title="Webserver and security"
            style="background-color: lightblue; margin: 2px;">
            <template v-slot:header>
              <b-icon icon="shield-lock-fill" font-scale="2"></b-icon>
            </template>
            <p>
              Setup, configuration and maintenance of nginx or apache web server to expose your website over https (ssl/tls).
              Purchase, configuration and maintenance of SSL/TLS Certificates.
            </p>
            <template v-slot:footer>
              <center>
                <b-button>Read more</b-button>
              </center>
            </template>
          </b-card>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6" style="display: flex; align-items: stretch;">
          <b-card
            header-class="text-center"
            title="Mobile solutions for every platform"
            style="background-color: lightblue; margin: 2px;">
            <template v-slot:header>
              <b-icon icon="phone-landscape-fill" font-scale="2"></b-icon>
            </template>
            <p>
              Mobile Apps, responsive websites and games for every platform.
            </p>
            <template v-slot:footer>
              <center>
                <b-button>Read more</b-button>
              </center>
            </template>
          </b-card>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6" style="display: flex; align-items: stretch;">
          <b-card
            header-class="text-center"
            title="Analytics, SERP, SEO and SMM. Full online marketing support"
            style="background-color: lightblue; margin: 2px;">
            <template v-slot:header>
              <b-icon icon="graph-up" font-scale="2"></b-icon>
            </template>
            <p>
              Google analytics, search console, search engine result pages analysis, structured data (schema.org), tag manager etc.
            </p>
            <template v-slot:footer>
              <center>
                <b-button>Read more</b-button>
              </center>
            </template>
          </b-card>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6" style="display: flex; align-items: stretch;">
          <b-card
            header-class="text-center"
            title="Big data"
            style="background-color: lightblue; margin: 2px;">
            <template v-slot:header>
              <b-icon icon="server" font-scale="2"></b-icon>
            </template>
            <p>
              Mongo DB, Elastic search, Neo4j, etc. NoSql databases, message queuing - RabbitMQ, MQTT, analytical tools - Kibana, Graylog, Logstash, Beats. Setup and analysis of huge amounts of heterogeneous data.
            </p>
            <template v-slot:footer>
              <center>
                <b-button>Read more</b-button>
              </center>
            </template>
          </b-card>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6" style="display: flex; align-items: stretch;">
          <b-card
            header-class="text-center"
            title="Google Ads (AdSense / AdWords)"
            style="background-color: lightblue; margin: 2px;">
            <template v-slot:header>
              <b-icon icon="cash" font-scale="2"></b-icon>
            </template>
            <p>
              Increase and monetize traffic, clicks and impressisons, organize campaigns and target customers.
            </p>
            <template v-slot:footer>
              <center>
                <b-button>Read more</b-button>
              </center>
            </template>
          </b-card>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6" style="display: flex; align-items: stretch;">
          <b-card
            header-class="text-center"
            title="Performance analysis and optimization"
            style="background-color: lightblue; margin: 2px;">
            <template v-slot:header>
              <b-icon icon="stopwatch-fill" font-scale="2"></b-icon>
            </template>
            <p>
              Architecture analysis, Web page (CSS, JS, images) optimizations, Database and Web engine tuning, Middleware improvements.
            </p>
            <template v-slot:footer>
              <center>
                <b-button>Read more</b-button>
              </center>
            </template>
          </b-card>
        </div>
      </div>
    </div>
    <div style="text-align: center; font-size: 28px;">
      <strong>Customers and Projects</strong>
    </div>
    <div class="container-fluid">
      <div>
        <strong>Impuls e.V. Frankfurt am Main</strong> - <b-link href="http://impuls-frankfurt.com">Home Page</b-link>
      </div>
      <div class="row">
        <b-carousel
          class="col-md-4 col-sm-6"
          id="carousel-impf"
          style="text-shadow: 0px 0px 2px black;"
          fade
          indicators
          controls>
          <b-carousel-slide
            caption="Home page">
            <b-img-lazy slot="img" width="320" height="240" src="../assets/home/crsl-impf-1.png"></b-img-lazy>
          </b-carousel-slide>
          <b-carousel-slide
            caption="Events">
            <b-img-lazy slot="img" width="320" height="240" src="../assets/home/crsl-impf-2.png"></b-img-lazy>
          </b-carousel-slide>
          <b-carousel-slide
            caption="Events">
            <b-img-lazy slot="img" width="320" height="240" src="../assets/home/crsl-impf-3.png"></b-img-lazy>
          </b-carousel-slide>
          <b-carousel-slide
            caption="Events">
            <b-img-lazy slot="img" width="320" height="240" src="../assets/home/crsl-impf-4.png"></b-img-lazy>
          </b-carousel-slide>
          <b-carousel-slide
            caption="Events">
            <b-img-lazy slot="img" width="320" height="240" src="../assets/home/crsl-impf-5.png"></b-img-lazy>
          </b-carousel-slide>
          <b-carousel-slide
            caption="Catalog">
            <b-img-lazy slot="img" width="320" height="240" src="../assets/home/crsl-impf-6.png"></b-img-lazy>
          </b-carousel-slide>
          <b-carousel-slide
            caption="Blog">
            <b-img-lazy slot="img" width="320" height="240" src="../assets/home/crsl-impf-7.png"></b-img-lazy>
          </b-carousel-slide>
          <b-carousel-slide
            caption="Blog">
            <b-img-lazy slot="img" width="320" height="240" src="../assets/home/crsl-impf-8.png"></b-img-lazy>
          </b-carousel-slide>
        </b-carousel>
        <div class="col-md-8 col-sm-6">
          <p>
            High scalable, high available, multilingual responsive Bootstrap based website, deployed on the Heroku cloud. Full featured blog, python (flask) REST API / backend and MongoDB persistance layer, OAuth2 authentication.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/plugins/bootstrap-vue-main'
import homeMeta from './meta/home'

export default {
  name: 'home',
  meta: {
    localizedHtmlMeta: homeMeta
  }, // meta
  created () {
    this.$tracker('event', 'screen_view', {
      'app_name': 'geekline-web',
      'screen_name': 'Home'
    })
  },
  components: {
  }
}
</script>
<style scoped>

.home {
  margin-top: 85px;
}
.cmpn-card-btn {
  margin-bottom: 1px;
}
</style>
<!-- TODO: it is a bad practice! not recommended
<style>
.carousel .carousel-indicators li {
  background-color: green;
}
.carousel .carousel-indicators li.active {
  background-color: red;
}
</style>
-->
