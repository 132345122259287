import Vue from 'vue'
import router from './router'
import store from './store'
import '@babel/polyfill'
// import '@/plugins/bootstrap-vue'
import config from '@/config'
import { i18n } from '@/plugins/i18n'
import { Auth0Plugin } from '@/services/auth'
import App from './App.vue'

Vue.config.productionTip = false

// TODO: check if necessary
Vue.prototype.$config = config

// online tracking
window.dataLayer = window.dataLayer || []
Vue.prototype.$tracker = function () {
  if (process.env.NODE_ENV === 'production') {
    window.dataLayer.push(arguments)
  } else {
    // console.warn('TRACE-REMOVE: tracker: No tracking @ noprod env! process.env.NODE_ENV is: ' +
    //   process.env.NODE_ENV)
  }
}

// auth
Vue.use(Auth0Plugin, {
  // domain: 'oggo.eu.auth0.com',
  // clientId: 'KOfNTMZw4j4fhSvLCQ3dCSQgA61pjJKR',
  // audience: 'https://geekline.org/api/v0.1',
  domain: config.auth0Domain,
  clientId: config.auth0ClientId,
  audience: config.auth0Audience,
  onRedirectCallback: appState => {
    // console.log('TRACE-REMOVE: main.js Auth0Plugin.onRedirectCallback: appState is: ', appState)
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  }
})

new Vue({
  router,
  store,
  i18n,
  created: function () {
    this.$tracker('js', new Date())
    this.$tracker('config', 'UA-88349067-1')
  },
  render: h => h(App)
}).$mount('#app')
