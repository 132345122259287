const moduleNamespace = 'glapi'

const states = {
  SUBSCR_TYPE_CAT: 'subscrTypeCat',
  INST_TYPE_CAT: 'instTypeCat',
  CONTENT_TYPE_CAT: 'contentTypeCat',
  CONTENT: 'content',
  COMM_PRODUCT_CTGY_TYPE_CAT: 'commProductCtgyTypeCat',
  ORDERS: 'orders',
  ORDER_DETAILS: 'orderDetails',
  PAYM_ORDERS: 'paymOrders',
  DOCS: 'docs'
}

const getters = {
}

const mutations = {
  SET_SUBSCR_TYPE_CAT: 'setSubscrTypeCat',
  SET_INST_TYPE_CAT: 'setInstTypeCat',
  SET_CONTENT_TYPE_CAT: 'setContentTypeCat',
  SET_CONTENT: 'setContent',
  ADD_CONTENT: 'addContent',
  REMOVE_CONTENT: 'removeContent',
  SET_COMM_PRODUCT_CTGY_TYPE_CAT: 'setCommProductCtgyTypeCat',
  SET_ORDERS: 'setOrders',
  SET_ORDER_DETAILS: 'setOrdeDetailsr',
  SET_PAYM_ORDERS: 'setPaymOrders',
  SET_DOCS: 'setDocs'
}

const actions = {
  FETCH_SUBSCR_TYPE_CAT: 'fetchSubscrTypeCat',
  FETCH_INST_TYPE_CAT: 'fetchInstTypeCat',
  FETCH_CONTENT_TYPE_CAT: 'fetchContentTypeCat',
  UPLOAD_CONTENT: 'uploadContent', // upload synonym to save
  FETCH_CONTENT: 'fetchContent',
  ERASE_CONTENT: 'eraseContent',
  FETCH_COMM_PRODUCT_CTGY_TYPE_CAT: 'fetchCommProductCtgyTypeCat',
  FETCH_ORDERS: 'fetchOrders',
  FETCH_ORDER_DETAILS: 'fetchOrderDetails',
  SAVE_ORDER_DETAILS: 'saveOrderDetails',
  FETCH_PAYM_ORDERS: 'fetchPaymOrders',
  CREATE_ONBOARDING: 'createOnboarding',
  UPDATE_ONBOARDING_COMPLETE: 'updateOnboardingComplete',
  CREATE_PAYMENT_ORDER: 'createPaymentOrder',
  UPDATE_PAYMENT_ORDER: 'updatePaymentOrder',
  FETCH_DOCS: 'fetchDocs'
}

function getNamespacedName (name) {
  return moduleNamespace + '/' + name
}

export { states, getters, mutations, actions, moduleNamespace, getNamespacedName }
