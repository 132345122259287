export const CONTENT_TYPE_DEFS = Object.freeze({
  TEXT_ONLY: 'ct-textOnly',
  IMAGE_ONLY: 'ct-imageOnly',
  COMM_PRODUCT: 'ct-commProduct',
  BLOG_POST: 'ct-blogPost',
  ARTICLE: 'ct-article',
  EVENT: 'ct-event',
  DONATION: 'ct-donation',
  COMM_AD: 'ct-commAd',
  PDF_DOC: 'ct-pdfDoc',
  PAYMENT_WIDGET: 'ct-paymentWidget'
})

export const PAYM_METHOD_TYPE_DEFS = Object.freeze({
  PREF_PAYM_PROVIDER: 'pmt-pref-paym-provider',
  INVOICE: 'pmt-invoice',
  COUPON: 'pmt-coupon'
})
