import Vue from 'vue'
import Router from 'vue-router'
import { authGuard } from '@/services/auth/authGuard'
import { i18n } from '@/plugins/i18n'
import EmptyParent from '@/components/router/EmptyParent.vue'
import Home from '@/views/Home.vue'
import homeMeta from '@/views/meta/home'
import productsMeta from '@/views/meta/products'
import servicesMeta from '@/views/meta/services'
import contactMeta from '@/views/meta/contact'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: i18n.locale // TODO: dynamic redirect does not work! Always the first locale.
    },
    {
      path: '/:locale',
      component: EmptyParent,
      children: [
        {
          path: '',
          name: 'home',
          component: Home,
          // component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
          // meta: Home.meta
          // TODO: OPTIMIZE: wrap it in dynaic import
          meta: { localizedHtmlMeta: homeMeta }
        },
        {
          path: 'products',
          name: 'products',
          // component: Products,
          component: () => import(/* webpackChunkName: "main" */ '@/views/Products'),
          // meta: Products.meta
          meta: { localizedHtmlMeta: productsMeta }
        },
        {
          path: 'services',
          name: 'services',
          // component: Services,
          component: () => import(/* webpackChunkName: "main" */ '@/views/Services'),
          // meta: Services.meta
          meta: { localizedHtmlMeta: servicesMeta }
        },
        {
          path: 'contact',
          name: 'contact',
          // component: Contact,
          component: () => import(/* webpackChunkName: "main" */ '@/views/Contact'),
          // meta: Contact.meta
          meta: { localizedHtmlMeta: contactMeta }
        },
        {
          path: 'dashboard',
          name: 'dashboard',
          // component: Dashboard,
          component: () => import(/* webpackChunkName: "dash" */ '@/views/Dashboard'),
          // meta: Dashboard.meta,
          beforeEnter: authGuard
        },
        {
          path: 'dashboard/inst/:instId/manage/content',
          name: 'manageInst',
          // component: ManageInstContent,
          component: () => import(/* webpackChunkName: "dash" */ '@/views/ManageInstContent'),
          // meta: ManageInstContent.meta,
          beforeEnter: authGuard
        },
        // TODO: consolidate all the manageInst* routes and route them in a majour component
        {
          path: 'dashboard/inst/:instId/manage/orders',
          name: 'manageInstOrders',
          // component: ManageInstOrders,
          component: () => import(/* webpackChunkName: "dash" */ '@/views/ManageInstOrders'),
          // meta: ManageInstOrders.meta,
          beforeEnter: authGuard
        },
        {
          path: 'dashboard/inst/:instId/manage/payments',
          name: 'manageInstPayments',
          component: () => import(/* webpackChunkName: "dash" */ '@/views/ManageInstPayments'),
          beforeEnter: authGuard
        },
        {
          path: 'dashboard/inst/:instId/manage/schedule',
          name: 'manageInstSchedule',
          // component: ManageInstSchedule,
          component: () => import(/* webpackChunkName: "dash" */ '@/views/ManageInstSchedule'),
          // meta: ManageInstSchedule.meta,
          beforeEnter: authGuard
        },
        {
          path: 'dashboard/inst/:instId/manage/documents',
          name: 'manageInst',
          // component: ManageInstContent,
          component: () => import(/* webpackChunkName: "dash" */ '@/views/ManageInstDocs'),
          // meta: ManageInstDocs.meta,
          beforeEnter: authGuard
        },
        {
          path: 'dashboard/inst/:instId/settings/content',
          name: 'contentSettings',
          // component: ContentSettings,
          component: () => import(/* webpackChunkName: "dash" */ '@/views/ContentSettings'),
          // meta: ContentSettings.meta,
          beforeEnter: authGuard
        },
        {
          path: 'dashboard/inst/:instId/settings/orders',
          name: 'ordersSettings',
          // component: OrdersSettings,
          component: () => import(/* webpackChunkName: "dash" */ '@/views/OrdersSettings'),
          // meta: OrdersSettings.meta,
          beforeEnter: authGuard
        },
        {
          path: 'dashboard/inst/:instId/settings/schedule',
          name: 'scheduleSettings',
          // component: ScheduleSettings,
          component: () => import(/* webpackChunkName: "dash" */ '@/views/ScheduleSettings'),
          // meta: ScheduleSettings.meta,
          beforeEnter: authGuard
        },
        {
          path: 'dashboard/inst/:instId/settings/payments',
          name: 'paymentsSettings',
          component: () => import(/* webpackChunkName: "dash" */ '@/views/PaymentsSettings'),
          // meta: ScheduleSettings.meta,
          beforeEnter: authGuard
        },
        {
          path: 'cb/:instKey/onboardingComplete/:pitType',
          name: 'onboardingComplete',
          component: () => import(/* webpackChunkName: "dash" */ '@/views/cb/OnboardingComplete')
        },
        {
          path: 'acc/settings',
          name: 'accSettings',
          // component: AccountSettings,
          component: () => import(/* webpackChunkName: "dash" */ '@/views/AccountSettings'),
          // meta: AccountSettings.meta,
          beforeEnter: authGuard
        },
        {
          path: 'author/oggo',
          name: 'autor/aboutOggo',
          // component: AboutOggo,
          component: () => import(/* webpackChunkName: "main" */ '@/views/author/AboutOggo')
          // meta: AboutOggo.meta
        },
        {
          path: 'version',
          name: 'version',
          component: () => import ('@/views/Version.vue')
        },
        {
          path: 'validation',
          name: 'validation',
          component: () => import ('@/views/Validation.vue')
        }
      ]
    }
  ]
})

/** As per https://alligator.io/vuejs/vue-router-modify-head/ */
function __handleMeta (route) {
  let _meta
  console.log('TRACE-REMOVE: router.__handleMeta: route is: ', route)
  _meta = route.matched.slice().reverse().find(r => r.meta).meta

  if (_meta) {
    let _htmlMeta

    if (_meta.localizedHtmlMeta) {
      _htmlMeta = _meta.localizedHtmlMeta(route.params.locale)
    } else if (_meta.htmlMeta) {
      _htmlMeta = _meta.htmlMeta()
    } else {
      _htmlMeta = {}
    }

    if (_htmlMeta.title) {
      document.title = 'Geekline - ' + _htmlMeta.title
    }
    Array.from(document.querySelectorAll('[data-vue-router-controlled]'))
      .map(el => el.parentNode.removeChild(el))

    if (_htmlMeta.metaTags) {
      // Turn the meta tag definitions into actual elements in the head.
      _htmlMeta.metaTags.map(tagDef => {
        const tag = document.createElement('meta')

        Object.keys(tagDef).forEach(key => {
          tag.setAttribute(key, tagDef[key])
        })

        // We use this to track which meta tags we create, so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '')

        return tag
      }).forEach(tag => document.head.appendChild(tag)) // Add the meta tags to the document head.
    }

    if (_htmlMeta.jsonld) {
      const scriptEl = document.createElement('script')
      scriptEl.type = 'application/ld+json'
      scriptEl.text = JSON.stringify(_htmlMeta.jsonld)
      document.head.appendChild(scriptEl)
    }
  }
}

router.beforeEach((to, from, next) => {
  // TODO to be investigated why is it necessary?!
  i18n.locale = to.params.locale
  __handleMeta(to)

  next()
})

Router.prototype.localizedRoute = (r) => {
  return '/' + i18n.locale + r
}

export default router
