<template lang="html">
  <b-navbar class="fixed-top" toggleable="md" type="dark" variant="info">
    <b-navbar-brand :href="$router.localizedRoute('/')">
      <b-img src="../../assets/geekline-logo-2-45x50.png" class="cmpnLogo"></b-img>
      Geekline
    </b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item :href="$router.localizedRoute('/products')">{{ $t('header.products') }}</b-nav-item>
        <b-nav-item :href="$router.localizedRoute('/services')">Services</b-nav-item>
        <b-nav-item :href="$router.localizedRoute('/')">Solutions</b-nav-item>
        <b-nav-item :href="$router.localizedRoute('/')">Integrations</b-nav-item>
        <b-nav-item :href="$router.localizedRoute('/')">Technologies</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <!-- <b-nav-item :href="$router.localizedRoute('/')">Explore</b-nav-item> -->
        <b-nav-item href="/docs">Explore</b-nav-item>
        <div v-if="!$auth.loading">
          <b-nav-item v-if="!$auth.isAuthenticated" @click="login">Sign in</b-nav-item>
          <!-- <b-nav-item v-if="$auth.isAuthenticated" @click="logout">Sign out</b-nav-item> -->
          <b-nav-item-dropdown
            v-if="$auth.isAuthenticated"
            id="account-settings"
            text="Account"
            toggle-class="nav-link-custom"
            right
          >
            <b-dropdown-item :href="$router.localizedRoute('/acc/settings')">Settings</b-dropdown-item>
            <b-dropdown-item :href="$router.localizedRoute('/dashboard')">Dashboard</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click="logout">Sign out</b-dropdown-item>
          </b-nav-item-dropdown>
        </div>
        <div v-else><b-nav-item>Loading...</b-nav-item></div>
        <b-nav-item :href="$router.localizedRoute('/contact')">{{ $t('header.contact') }}</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  methods: {
    login () {
      this.$auth.loginWithRedirect({ appState: { targetUrl: this.$router.localizedRoute('/dashboard') } })
    }, // login
    logout () {
      this.$auth.logout({ returnTo: window.location.origin })
    } // logout
  }
}
</script>

<style lang="css" scoped>

.cmpnLogo:hover {
  transition: all 0.5s ease-in-out;
  transform: rotateZ(360deg);
}
</style>
