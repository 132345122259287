import Vue from 'vue'

import {
  ButtonPlugin,
  CardPlugin,
  CarouselPlugin,
  FormSelectPlugin,
  ImagePlugin,
  LinkPlugin,
  NavPlugin,
  NavbarPlugin,
  ToastPlugin,
  BIcon,
  BIconCash,
  BIconCloudCheckFill,
  BIconCodeSquare,
  BIconGlobe,
  BIconGraphUp,
  BIconImage,
  BIconMap,
  BIconPhoneLandscapeFill,
  BIconServer,
  BIconShieldLockFill,
  BIconStopwatchFill
} from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.min.css'
/* Without it following styles are missing: toast, */
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(ButtonPlugin)
Vue.use(CardPlugin)
Vue.use(CarouselPlugin)
Vue.use(FormSelectPlugin)
Vue.use(ImagePlugin)
Vue.use(LinkPlugin)
Vue.use(NavPlugin)
Vue.use(NavbarPlugin)
Vue.use(ToastPlugin)

Vue.component('BIcon', BIcon)
Vue.component('BIconCash', BIconCash)
Vue.component('BIconCloudCheckFill', BIconCloudCheckFill)
Vue.component('BIconCodeSquare', BIconCodeSquare)
Vue.component('BIconGlobe', BIconGlobe)
Vue.component('BIconGraphUp', BIconGraphUp)
Vue.component('BIconImage', BIconImage)
Vue.component('BIconMap', BIconMap)
Vue.component('BIconPhoneLandscapeFill', BIconPhoneLandscapeFill)
Vue.component('BIconServer', BIconServer)
Vue.component('BIconShieldLockFill', BIconShieldLockFill)
Vue.component('BIconStopwatchFill', BIconStopwatchFill)
