import { content as contentSvc } from '@/services/glapi'
import { CONTENT_TYPE_DEFS } from '@/services/glapi/constants'
import { glapiHttpInst } from '@/services/http'
// import { uploadImage } from '@/services/cloudStorage'
import * as cloudStorage from '@/services/cloudStorage'

async function upload (contentData, authHeaders) {
  console.log('TRACE-REMOVE: handlers.content->upload: contentData is: ', contentData)
  console.log('TRACE-REMOVE: handlers.content->upload: authHeaders is: ', authHeaders)
  if ((contentData.typeCode === 'ct-imageOnly') ||
    (contentData.typeCode === 'ct-commProduct' && !contentData.fragments.imgSrc)) {
    let _img = await cloudStorage.uploadImage(contentData.fragments.imgFile)
    console.log('TRACE-REMOVE: handlers.content: uploadImageOnly: _img is: ', _img)
    delete contentData.fragments.imgFile
    contentData['fragments']['imgUrl'] = _img['url']
  } else if (CONTENT_TYPE_DEFS.PDF_DOC === contentData.typeCode) {
    contentData.formData.append('instKey', contentData.instKey)
    // headers: { 'Content-Type': 'multipart/form-data' }
    let _respUploadPdf = await glapiHttpInst.post(
      contentSvc.getUri(contentSvc.getEndPointsCat().uploadPdf),
      contentData.formData,
      authHeaders
    )
    console.log('TRACE-REMOVE: handlers.content.upload: _respUploadPdf is: ', _respUploadPdf)
    delete contentData.formData
    if (_respUploadPdf && _respUploadPdf.data && _respUploadPdf.data.status === 'success') {
      contentData['fragments']['pdfFileKey'] = _respUploadPdf.data.payload.pdfFileKey
    } else {
      throw contentSvc.getError(contentSvc.getErrorsCat().networkError)
    }
  }
  let _resp = await glapiHttpInst.post(
    contentSvc.getUri(contentSvc.getEndPointsCat().upload),
    contentData,
    authHeaders)
  // console.log('TRACE-REMOVE: store.glapi.uploadContent: _resp is: ', _resp)
  // TODO: throw exeption if there is no success response
  // if (_resp.data.status !== 'success') { // TODO: consider new login recommendation!
  //   throw contentSvc.getError(contentSvc.getErrorsCat().serverError)
  // }

  let uploadedItem = Object.assign({}, _resp.data.payload)
  // let uploadedItem = {}
  uploadedItem['typeCode'] = contentData.typeCode
  uploadedItem['fragments'] = contentData['fragments']
  uploadedItem['jsonld'] = contentData['jsonld']

  return uploadedItem
}

export { upload }
