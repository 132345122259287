const moduleNamespace = 'system'

const states = {
  LAST_PERSISTED: 'lastPersisted'
}

// getters
const getters = {}

// mutations
const mutations = {}

// actions
const actions = {}

function getNamespacedName (name) {
  return moduleNamespace + '/' + name
}

export { states, getters, mutations, actions, moduleNamespace, getNamespacedName }
