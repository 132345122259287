const pageDescription = 'Responsive web design in html5, javascript. Python,java based REST or GraphQL Backend. Cloud and Big Data solutions. Online Marketing and SEO. SSL, STL, OAuth2.'

export default (locale) => {
  return {
    title: 'Internet products, services, technologies and solutions', // 52-60 symbols
    metaTags: [
      {
        name: 'description', /** 155-160 symbols */
        content: pageDescription
      }, // 150-165 symbols
      {
        property: 'og:title',
        content: 'Internet based products, responsive and mobile web design, HTML5, Javascript, Vuejs, Python, Java, etc. SEO and Google Ads. High scalability and availability.'
      },
      {
        property: 'og:description',
        content: pageDescription
      },
      {
        property: 'og:url',
        content: 'https://gloo.geekline.org/' + locale
      }
    ],
    jsonld: {
      '@context': 'https://schema.org',
      '@graph': [
        {
          '@type': 'Organization',
          '@id': 'https://gloo.geekline.org/#organization',
          'url': 'https://gloo.geekline.org/' + locale,
          'name': 'Geekline',
          'logo': [
            {
              '@type': 'ImageObject',
              '@id': 'https://gloo.geekline.org/#logo',
              'url': 'https://gloo.geekline.org/geekline-logo-2-45x50.png',
              'width': 45,
              'height': 50
            }
          ],
          'foundingDate': '2007',
          'sameAs': [
            'https://www.facebook.com/Geekline-111534267101811'
          ],
          'address': {
            '@type': 'PostalAddress',
            'streetAddress': 'Speicherstr. 35',
            'addressLocality': 'Frankfurt am Main',
            'addressRegion': 'Hessen',
            'postalCode': '60327',
            'addressCountry': 'Deutschland'
          },
          'founders': [
            {
              '@type': 'Person',
              '@id': 'https://gloo.geekline.org/' + locale + '/author/ognyanivanov/#schema-author',
              'url': 'https://gloo.geekline.org/' + locale + '/author/oggo/',
              'name': 'Ognyan Ivanov',
              'image': {
                '@type': 'ImageObject',
                '@id': 'https://gloo.geekline.org/author/oggo/#author-image',
                'url': 'https://gloo.geekline.org/author/oggo/Ivanov-1-3000x2950-2.jpg',
                'caption': 'Ognyan Ivanov'
              },
              'jobTitle': 'CEO and founder at Geekline',
              'description': 'Ognyan is the CEO and founder of Geekline and main developer and architect.',
              'sameAs': [
                'https://web.facebook.com/oggzter',
                'https://www.linkedin.com/in/ognyan-ivanov-40bb625/'
              ]
            }
          ]
        },
        {
          '@type': 'WebSite',
          '@id': 'https://gloo.geekline.org/#website',
          'url': 'https://gloo.geekline.org/' + locale,
          'name': 'geekline website',
          'description': 'geekline website'
          // TODO: add potentialAction
        },
        {
          '@type': 'WebPage',
          '@id': 'https://gloo.geekline.org/' + locale + '/#webpage',
          'url': 'https://gloo.geekline.org/' + locale,
          'name': 'geekline - Internet products, services, technologies and solutions',
          'isPartOf': {
            '@id': 'https://gloo.geekline.org/#website'
          },
          'inLanguage': locale,
          'potentialAction': [
            {
              '@type': 'ReadAction',
              'target': [
                'https://gloo.geekline.org/' + locale
              ]
            }
          ],
          'datePublished': '2020-01-25T12:40:11+01:00',
          'dateModified': '2022-03-27T21:40:11+01:00',
          'description': pageDescription
        }
        // TODO: add ItemList
      ]
    }
  }
}
