import Vue from 'vue'
import Vuex from 'vuex'
import account from './modules/account'
import glapi from './modules/glapi'
import system from './modules/system'
import storePersister from './StorePersister'
// import storePersister from './StorePersister'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: { account, glapi, system },
  plugins: [ storePersister ]
  // strict: process.env.NODE_ENV !== 'production',
  // plugins: [ storePersister ]
})
