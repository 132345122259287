function replaceParams (payload, baseStr) {
  var _ret = baseStr
  var i = 0
  if (payload) {
    payload.forEach(e => {
      const _repl = ':' + i++
      _ret = _ret.replace(_repl, e)
    })
  }
  return _ret
}

const authHttpHeaders = (token) => { return { headers: { Authorization: `Bearer ${token}` } } }

function catalogify (obj) {
  var catalog = Object.keys(obj).reduce(function (ret, k) {
    ret[k] = k
    return ret
  }, {})
  return catalog
}

function isUndefinedOrNull (obj) {
  if (typeof obj === 'undefined' || obj === null) {
    return true
  }
  return false
}

export { replaceParams, isUndefinedOrNull, catalogify, authHttpHeaders }
