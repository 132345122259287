import config from '@/config'

export async function uploadImage (imgFile) {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest()
    var fd = new FormData()
    xhr.open('POST', config.imgCloudStorage, true)
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
    xhr.onreadystatechange = () => {
      if (xhr.readyState === xhr.DONE) {
        if (xhr.status === 200) {
          // File uploaded successfully
          var response = JSON.parse(xhr.responseText)
          // console.log('TRACE-REMOVE: service.cloudStorage.uploadImage: response.secure_url is: ', response.secure_url)
          let _result = {}
          _result['url'] = response.secure_url
          _result['publicId'] = response.public_id
          resolve(_result)
        } else {
          // this.notify('Error during upload! Status / text :' + xhr.status +
          //   ' / ' + xhr.statusText)
          console.error('Error during upload! Status / text :' + xhr.status +
            ' / ' + xhr.statusText)
          reject(new Error('Error during upload! Status / text :' + xhr.status +
            ' / ' + xhr.statusText))
        }
      } else {
        // WARN: do not reject here!
        // console.log('TRACE-REMOVE: xhr.onreadystatechange: readyState: ' + xhr.readyState)
      }
    }
    fd.append('upload_preset', config.imgCloudStoragePreset)
    // TODO: consider setting the account key
    fd.append('tags', config.envTag)
    fd.append('file', imgFile)
    xhr.send(fd)
  })
}
