
import { LOCAL_STORAGE_STORE_KEY } from '@/store/modules/system'
import { moduleNamespace as accntNs } from '@/store/modules/account/names'

export default function storePersister (store) {
  /** Gets called on every mutation. Activate in debug mode
  store.subscribe((mutation, state) => {
    console.log('TRACE-REMOVE: subscribe: mutation: ' + JSON.stringify(mutation))
  })
  */
  /** For some reason doesn't work
  store.watch(mutations.loginOrRefreshTokenData, (newV, oldV) => {
    console.log('TRACE-REMOVE: watch->istp/LOGIN_OR_REFRESH_TOKEN_DATA: new / old is: ' + newV + ' / ' + oldV)
  })
  */
  store.watch((state) => { return state.account }, (nv, ov) => {
    // console.log('account.token old: ', JSON.stringify(ov))
    // console.log('account.token new: ', JSON.stringify(nv))
    // nv.system.lastPersisted = Date.now()
    window.localStorage.setItem(LOCAL_STORAGE_STORE_KEY + '_' + accntNs, JSON.stringify(nv))
  },
  { deep: true })
}
