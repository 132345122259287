/** object must be flat to enable Object.assign.
As soon as deep copy is available will be changed.
*/
export default {
  auth0Audience: 'https://geekline.org/api/v0.1',
  auth0ClientId: 'KOfNTMZw4j4fhSvLCQ3dCSQgA61pjJKR',
  auth0Domain: 'oggo.eu.auth0.com',
  glapiRootUri: '/api/v0.1',
  glwebValidateSlug: '/en/validation',
  imgCloudStorage: 'https://api.cloudinary.com/v1_1/dsjszihdo/image/upload',
  openaiApiKey: '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',
  paypalBaseUrl: 'https://www.paypal.com/sdk/js?'
}
