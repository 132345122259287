const pageDescription = 'Amazing services. Play, Start, Business, Enterprise, Corporate'
const pageTitle = 'Our rich products'
const pageSlug = 'services'

export default (locale) => {
  return {
    title: pageTitle,
    metaTags: [
      {
        name: 'description', /** 155-160 symbols */
        content: pageDescription
      }, // 150-165 symbols
      {
        property: 'og:title',
        content: pageTitle
      },
      {
        property: 'og:description',
        content: pageDescription
      },
      {
        property: 'og:url',
        content: 'https://geekline.org/' + locale + '/' + pageSlug
      }
    ],
    jsonld: {
      '@context': 'https://schema.org',
      '@type': 'WebPage',
      '@id': 'https://geekline.org/' + locale + '/' + pageSlug + '/#webpage',
      'url': 'https://geekline.org/' + locale + '/' + pageSlug,
      'inLanguage': locale,
      'name': 'geekline - products page',
      'isPartOf': {
        '@id': 'https://geekline.org/#website'
      },
      'datePublished': '2020-03-14T10:40:11+01:00',
      'dateModified': '2020-03-14T10:40:11+01:00',
      'description': pageDescription
    }
    // TODO: add ItemList
  }
}
